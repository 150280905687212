















































































































































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { ActionMethod } from 'vuex';
import BaseTeamCard from '@improve/common-components/src/components/widgets/BaseTeamCard.vue';
import BaseAddButton from '@improve/common-components/src/components/widgets/BaseAddButton.vue';
import Team from '@improve/common-utils/src/model/Team';
import StatsData from '@improve/common-utils/src/model/StatsData';
import Ticket from '@improve/common-utils/src/model/Ticket';
import BaseTicketCard from '@/components/ticket/BaseTicketCard.vue';

@Component({
  name: 'SearchResults',
  components: {
    BaseTeamCard,
    BaseAddButton,
    BaseTicketCard
  }
})
export default class SearchResults extends Vue {
  @Getter teamStatsByID!: Map<string, StatsData>;

  @Getter foundTickets?: Array<Ticket>;

  @Getter foundTeams?: Array<Team>;

  @Action searchTickets!: ActionMethod;

  @Action searchTeams!: ActionMethod;

  searchInProgress = false;

  get viewAllClassTeams(): string {
    return this.foundTeams!.length > 6 ? 'view-all' : '';
  }

  get viewAllClassTickets(): string {
    return this.foundTickets!.length > 6 ? 'view-all' : '';
  }

  get teamDescription(): string {
    return this.foundTeams!.length === 1 ? 'team' : 'teams';
  }

  get ticketDescription(): string {
    return this.foundTickets!.length === 1 ? 'improve' : 'improves';
  }

  get viewAllDescription(): string {
    return this.$t('page.dashboard.viewAll').toString();
  }

  async created(): Promise<void> {
    if (this.$route.query.search) {
      this.searchInProgress = true;
      await this.searchTickets(this.$route.query.search!.toString());
      await this.searchTeams(this.$route.query.search!.toString());
      this.searchInProgress = false;
    }
  }

  viewAllTickets(): void {
    this.$router.push({
      name: 'SearchAllResults',
      query: { search: this.$route.query.search!.toString(), viewAllTickets: 'true' }
    });
  }

  viewAllTeams(): void {
    this.$router.push({
      name: 'SearchAllResults',
      query: { search: this.$route.query.search!.toString(), viewAllTeams: 'true' }
    });
  }

  fetchTeamStat(team: Team): StatsData {
    if (!this.teamStatsByID || !this.teamStatsByID.has(team.id!)) {
      return new StatsData();
    }
    return this.teamStatsByID.get(team.id!)!;
  }

  goToTeam(teamId: string): void {
    this.$router.push({
      name: 'TeamDetails',
      params: { id: teamId }
    });
  }
}
